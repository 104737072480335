<template>
  <div class="parameter">
    <div class="parameter-item">
      <div class="title">考勤参数</div>
      <div class="content-box">
        <div class="content-item">
          <el-switch
            v-model="submitData.AtteNearbySignMode"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
          <span class="name">开启工作地附近考勤</span>
        </div>

        <div class="content-item">
          <div class="group">
            <el-input
              type="textarea"
              :rows="3"
              v-model="submitData.StoresAddress"
              class="textarea"
              @focus="onShowMap"
            ></el-input>

            <el-input
              v-model="submitData.Position"
              placeholder=""
              size="default"
              @focus="onShowMap"
            ></el-input>
          </div>
          <span class="name">选择地址</span>
        </div>
      </div>
    </div>

    <div class="parameter-item">
      <div class="title">会员协议</div>
      <div class="content-box">
        <div class="content-item">
          <el-switch
            v-model="submitData.ContractEnable"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
          <span class="name">开启会员协议</span>
        </div>

        <div class="content-item">
          <el-select
            v-model="submitData.ContractDefault"
            placeholder="请选择默认会员协议"
            :disabled="!submitData.ContractEnable"
            clearable
          >
            <el-option
              v-for="item in contractSelector"
              :key="item.ContractGuid"
              :label="item.Name"
              :value="item.ContractGuid"
            >
            </el-option>
          </el-select>
        </div>

        <div class="content-item">
          <span class="tips">不选择默认协议则使用会员卡协议，当默认协议和会员卡协议同时存在时，优先使用会员卡协议</span>
        </div>
      </div>
    </div>

    <div class="save-btn">
      <el-button type="primary" :disabled="saveDisabled" @click="savePreference"
        >保 存</el-button
      >
    </div>

    <DfWxmapDialog
      ref="DfWxmapDialog"
      @success="onSelectAddress"
    ></DfWxmapDialog>
  </div>
</template>

<script>
import Setting from "@/api/setting.js";

export default {
  data() {
    return {
      submitData: {
        AtteNearbySignMode: 0,
        StoresAddress: "",
        Position: "",
        ContractDefault: "",
        ContractEnable: 0,
      },
      contractSelector: [],
      saveDisabled: false,
    };
  },

  mounted() {
    this.initPreference();
  },

  methods: {
    // 初始化基础参数
    async initPreference() {
      try {
        let { data } = await Setting.initPreference();
        this.submitData = data.preferParams;
        this.contractSelector = data.contractSelector;
      } catch (err) {
        console.log(err);
      }
    },

    // 保存基础参数
    async savePreference() {
      let { submitData } = this
      this.saveDisabled = true;
      try {
        if (!submitData.ContractEnable) {
          submitData.ContractDefault = "";
        }
        let { errcode } = await Setting.savePreference(submitData);
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.initPreference();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.saveDisabled = false;
      }
    },

    onSelectAddress(event) {
      this.submitData.StoresAddress = event.StoresAddress;
      this.submitData.Position = event.Position;
    },

    onShowMap() {
      this.$refs.DfWxmapDialog.onShowDialog(this.submitData);
    },
  },
};
</script>

<style lang="less" scoped>
.parameter {
  position: relative;
  height: 100%;

  &,
  .parameter-item {
    .flex-col;
    align-items: unset;
  }

  .parameter-item {
    padding: 0 10px;
    .title {
      padding: 10px 10px;
      margin-top: 10px;
      font-size: 14px;
      font-weight: bold;
      border-bottom: 1px solid #ddd;
    }

    .content-box {
      .content-item {
        .flex-row;
        align-items: center;
        margin: 20px 10px;
        box-sizing: border-box;

        .group,
        .el-select {
          width: 400px;
          .el-textarea {
            margin-bottom: 10px;
          }
        }

        .name {
          margin-left: 5px;
        }

        .no-use {
          margin-left: 10px;
          color: tomato;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
      }

      .tips{
        margin-left: 10px;
        color: #999;
      }
    }
  }

  .save-btn {
    // .flex-row;
    // justify-content: center;
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 100%;
    margin: 20px 0;
    width: calc(- (50% / 2));
  }
}
</style>
